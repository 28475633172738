import React, { Component } from "react";
import swal from "sweetalert";
import { ColumnDirective, ColumnsDirective, Resize, GridComponent, Inject, Page, Sort, Filter, Group } from '@syncfusion/ej2-react-grids';

import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-awesome-modal";
var dateFormat = require("dateformat");
var _ = require("lodash");
class MyCases extends Component {
  constructor() {
    super();
    this.state = {
      Applications: [],
      PE: [],
      Advocates: [],
      ApplicationsProgress: [],
      CaseAnalysisDocuments: [],
      CaseAnalysis: [],
      HearingDates: [],
      interestedparties: [],
      AdditionalSubmisions: [],
      stdtenderdocs: [],
      TenderNo: "",
      TenderID: "",
      Applicantusername:"",
      caseOfficer: "",
      IsLoading: true,
      legalresearcherName: "",
      stenographerName: "",
      TenderValue: "",
      ApplicationID: "",
      TenderName: "",
      PEID: "",
      StartDate: "",
      ClosingDate: "",
      ApplicationREf: "",
      ApplicantID: "",
      AdendumDescription: "",
      EntryType: "",
      RequestDescription: "",
      GroundDescription: "",
      profile: true,
      openTracking: false,
      summary: false,
      IsUpdate: false,
      Documenttype: "",
      DocumenttypeID: "",
      selectedFile: null,
      loaded: 0,
      DocumentDescription: "",
      AddedAdendums: [],
      Panels: [],
      AdendumStartDate: "",
      RequestsAvailable: false,
      GroundsAvailable: false,
      AdendumsAvailable: false,
      AdendumClosingDate: "",
      AddAdedendums: false,
      ApplicantDetails: [],
      Applicantname: "",
      ApplicationGrounds: [],
      ApplicationDocuments: [],
      Applicationfees: [],
      AdditionalSubmisionsDocuments: [],
      FilingDate: "",
      PEName: "",
      ApplicationNo: "",
      openView: false,
      GroundNO: "",

      ApplicantLocation: "",
      ApplicantMobile: "",
      ApplicantEmail: "",
      ApplicantPIN: "",
      ApplicantWebsite: "",

      PEPOBox: "",
      PEPostalCode: "",
      PETown: "",
      PEPostalCode: "",
      PEMobile: "",
      PEEmail: "",
      PEWebsite: "",

      TotalAmountdue: "",

      ApplicantPostalCode: "",
      ApplicantPOBox: "",
      ApplicantTown: "",
      ResponseDocuments: [],
      ResponseDetails: [],
      GroundsDetails: [],
      PrayersDetails: [],
    };
    this.columntemplate = this.columntemplate.bind(this);
    this.fetchApplicantDetails = this.fetchApplicantDetails.bind(this);
    this.Resetsate = this.Resetsate.bind(this);
  }
  fetchApplicantDetails = (Applicant) => {
    fetch("/api/applicants/" + Applicant, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((ApplicantDetails) => {
        if (ApplicantDetails.length > 0) {
          this.setState({
            ApplicantPostalCode: ApplicantDetails[0].PostalCode,
          });
          this.setState({
            ApplicantPOBox: ApplicantDetails[0].POBox,
            ApplicantTown: ApplicantDetails[0].Town,
            ApplicantDetails: ApplicantDetails,
            Applicantname: ApplicantDetails[0].Name,
            ApplicantLocation: ApplicantDetails[0].Location,
            ApplicantMobile: ApplicantDetails[0].Mobile,
            ApplicantEmail: ApplicantDetails[0].Email,
            ApplicantPIN: ApplicantDetails[0].PIN,
            ApplicantWebsite: ApplicantDetails[0].Website,
          });

          this.setState({ ApplicantID: ApplicantDetails[0].ID });
        } else {
          swal("", ApplicantDetails.message, "error");
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };
  fetchApplications = () => {
    fetch("/api/casedetails/" + localStorage.getItem("UserName") + "/MyCases", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((ApplicantDetails) => {
        this.setState({ IsLoading: false });
        if (ApplicantDetails.length > 0) {
          this.setState({ Applications: ApplicantDetails });
        } else {
          swal("", ApplicantDetails.message, "error");
        }
      })
      .catch((err) => {
        this.setState({ IsLoading: false });
        swal("", err.message, "error");
      });
  };
  fetchApplicationGrounds = (Applicationno) => {
    this.setState({ ApplicationGrounds: [] });
    fetch("/api/grounds/" + Applicationno, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((ApplicationGrounds) => {
        if (ApplicationGrounds.length > 0) {
          this.setState({ ApplicationGrounds: ApplicationGrounds });
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };
  formatNumber = (num) => {
    let newtot = Number(num).toFixed(2);
    return newtot.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  fetchApplicationfees = (Applicationno) => {
    fetch("/api/applicationfees/" + Applicationno, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((Applicationfees) => {
        if (Applicationfees.length > 0) {
          this.setState({ Applicationfees: Applicationfees });
          this.setState({ TotalAmountdue: Applicationfees[0].total });
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };
  fetchApplicationDocuments = (Applicationno) => {
    this.setState({ ApplicationDocuments: [] });
    fetch("/api/applicationdocuments/" + Applicationno, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((ApplicationDocuments) => {
        if (ApplicationDocuments.length > 0) {
          this.setState({ ApplicationDocuments: ApplicationDocuments });
        } else {
          swal("", ApplicationDocuments.message, "error");
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };

  handleswitchMenu = (e) => {
    e.preventDefault();
    if (this.state.profile === false) {
      this.setState({ profile: true });
    } else {
      this.setState({ profile: false });
      this.Resetsate();
    }
  };
  GoBack = (e) => {
    e.preventDefault();
    this.setState({ summary: false });
  };

  Resetsate() {
    const data = {
      TenderNo: "",
      TenderID: "",
      TenderValue: "",
      ApplicationID: "",
      TenderName: "",
      PEID: "",
      StartDate: "",
      ClosingDate: "",
      ApplicationREf: "",
      AdendumDescription: "",
      EntryType: "",
      RequestDescription: "",
      GroundDescription: "",
      ApplicationGrounds: [],
      ApplicationDocuments: [],
      Applicationfees: [],
      FilingDate: "",
      PEName: "",
      ApplicationNo: "",
      AddedAdendums: [],
      AdendumStartDate: "",
      RequestsAvailable: false,
      GroundsAvailable: false,
      AdendumsAvailable: false,
      AdendumClosingDate: "",
      AddAdedendums: false,
      IsUpdate: false,
    };
    this.setState(data);
  }

  componentDidMount() {
    let token = localStorage.getItem("token");
    if (token == null) {
      localStorage.clear();
      return (window.location = "/#/Logout");
    } else {
      fetch("/api/ValidateTokenExpiry", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      })
        .then((response) =>
          response.json().then((data) => {
            if (data.success) {
              this.fetchApplications();
            } else {
              localStorage.clear();
              return (window.location = "/#/Logout");
            }
          })
        )
        .catch((err) => {
          localStorage.clear();
          return (window.location = "/#/Logout");
        });
    }
  }
  fetchTenderAdendums = (TenderID) => {
    this.setState({ AddedAdendums: [] });
    fetch("/api/tenderaddendums/" + TenderID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((AddedAdendums) => {
        if (AddedAdendums.length > 0) {
          this.setState({ AddedAdendums: AddedAdendums });
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };
  fetchinterestedparties = (ApplicationID) => {
    this.setState({ interestedparties: [] });
    fetch("/api/interestedparties/" + ApplicationID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((interestedparties) => {
        if (interestedparties.length > 0) {
          this.setState({ interestedparties: interestedparties });
        } else {
          toast.error(interestedparties.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  fetchAdditionalSubmisions = (ApplicationID) => {
    this.setState({
      AdditionalSubmisions: [],
    });
    fetch("/api/additionalsubmissions/" + ApplicationID + "/Applicant", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((AdditionalSubmisions) => {
        if (AdditionalSubmisions.length > 0) {
          this.setState({
            AdditionalSubmisions: AdditionalSubmisions,
          });
        } else {
          toast.error(AdditionalSubmisions.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  fetchAdditionalSubmisionsDocuments = (ApplicationID) => {
    this.setState({
      AdditionalSubmisionsDocuments: [],
    });
    fetch(
      "/api/additionalsubmissions/" + ApplicationID + "/Applicant/Documents",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((AdditionalSubmisions) => {
        if (AdditionalSubmisions.length > 0) {
          this.setState({
            AdditionalSubmisionsDocuments: AdditionalSubmisions,
          });
        } else {
          toast.error(AdditionalSubmisions.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  fetchApplicationProgress = (Applicationno) => {
    fetch("/api/applications/" + Applicationno + "/1/1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((ApplicationsProgress) => {
        if (ApplicationsProgress.length > 0) {
          this.setState({ ApplicationsProgress: ApplicationsProgress });
        }
      })
      .catch((err) => {
        // swal("", err.message, "error");
      });
  };
  fetchCaseAnalysis = (ApplicationNo) => {
    this.setState({ CaseAnalysis: [] });
    fetch("/api/Caseanalysis/" + ApplicationNo, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((PEDetails) => {
        if (PEDetails.length > 0) {
          this.setState({ CaseAnalysis: PEDetails });
        } else {
          swal("", PEDetails.message, "error");
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };
  fetchCaseAnalysisDocuments = (Applicationno) => {
    this.setState({ CaseAnalysisDocuments: [] });
    fetch("/api/Caseanalysis/" + Applicationno + "/Documents", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((BankSlips) => {
        if (BankSlips.length > 0) {
          this.setState({
            CaseAnalysisDocuments: BankSlips,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  PrintInvoicePDF = () => { 
    let filepath = 
              "/#/Invoice/" +
              this.state.ApplicationID+"/"+ this.state.ApplicationNo+"/"+ this.state.Applicantusername; 
              window.open(filepath,"_blank");
 
  };
  fetchResponseDetails = (ApplicationNo) => {
    this.setState({ ResponseDetails: [] });
    this.setState({ PrayersDetails: [] });
    this.setState({ PrayersDetails: [] });
    fetch(
      "/api/PEResponse/GetPEResponseDetailsPerApplication/" + ApplicationNo,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((ResponseDetails) => {
        if (ResponseDetails.length > 0) {
          this.setState({ ResponseDetails: ResponseDetails });
          const UserRoles = [_.groupBy(ResponseDetails, "GroundType")];

          if (UserRoles[0].Prayers) {
            this.setState({ PrayersDetails: UserRoles[0].Prayers });
          }
          if (UserRoles[0].Grounds) {
            this.setState({ GroundsDetails: UserRoles[0].Grounds });
          }
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };
  fetchResponseDocuments = (ApplicationNo) => {
    this.setState({ ResponseDocuments: [] });
    fetch("/api/PEResponse/Documents/" + ApplicationNo, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((ResponseDocuments) => {
        if (ResponseDocuments.length > 0) {
          this.setState({ ResponseDocuments: ResponseDocuments });
        }
      })
      .catch((err) => {
        swal("", err.message, "error");
      });
  };
  fetchPanels = (ApplicationNo) => {
    this.setState({ Panels: [] });
    fetch("/api/PanelApproval/" + ApplicationNo + "/Panel", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((Panels) => {
        if (Panels.length > 0) {
          this.setState({ Panels: Panels });
        } else {
          toast.error(Panels.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  fetchHearingDates = (ApplicationNo) => {
    this.setState({ HearingDates: [] });
    fetch("/api/CaseScheduling/" + ApplicationNo + "/Panel/Bookings", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((HearingDates) => {
        if (HearingDates.length > 0) {
          this.setState({ HearingDates: HearingDates });
        } else {
          toast.error(HearingDates.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  fetchAdvocates = (ApplicationID) => {
    this.setState({ Advocates: [] });
    fetch("/api/advocates/" + ApplicationID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((Advocates) => {
        if (Advocates.length > 0) {
          this.setState({ Advocates: Advocates });
        } else {
          toast.error(Advocates.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  handViewApplication = (k) => {
    this.setState({
      ResponseDetails: [],
      GroundsDetails: [],
      ApplicationsProgress: [],
      AddedAdendums: [],
      ApplicationGrounds: [],
      ApplicationDocuments: [],
      Applicationfees: [],
      ResponseDocuments: [],
      TotalAmountdue: "",
    });
    this.fetchAdvocates(k.ID);
    this.fetchApplicationGrounds(k.ID);
    this.fetchApplicationfees(k.ID);
    this.fetchApplicationDocuments(k.ID);
    this.fetchTenderAdendums(k.TenderID);
    this.fetchAdditionalSubmisions(k.ID);
    this.fetchAdditionalSubmisionsDocuments(k.ID);
    this.fetchinterestedparties(k.ID);
    this.fetchApplicationProgress(k.ApplicationNo);
    this.fetchApplicantDetails(k.Applicantusername);
    this.fetchCaseAnalysisDocuments(k.ApplicationNo);
    this.fetchCaseAnalysis(k.ApplicationNo);
    this.fetchResponseDetails(k.ApplicationNo);
    this.fetchResponseDocuments(k.ApplicationNo);
    this.fetchPanels(k.ApplicationNo);
    this.fetchHearingDates(k.ApplicationNo);
    const data = {
      Applicantusername:k.Applicantusername,
      Reaplication:k.Reaplication,
      OldCaseNumber:k.OldCaseNumber,
      PEPOBox: k.PEPOBox,
      PEPostalCode: k.PEPostalCode,
      PETown: k.PETown,
      caseOfficer: k.caseOfficer,
      legalresearcherName: k.legalresearcherName,
      stenographerName: k.stenographerName,
      PEPostalCode: k.PEPostalCode,
      PEMobile: k.PEMobile,
      PEEmail: k.PEEmail,
      PEWebsite: k.PEWebsite,
      TenderID: k.TenderID,
      ApplicationID: k.ID,
      ApplicationNo: k.ApplicationNo,
      TenderNo: k.TenderNo,
      ApplicationREf: k.ApplicationREf,
      PEName: k.PEName,
      AwardDate: dateFormat(
        new Date(k.AwardDate),
        "mediumDate"
      ),
      FilingDate: dateFormat(
        new Date(k.FilingDate),
        "mediumDate"
      ),
      TenderName: k.TenderName,
      Status: k.Status,
      TenderValue: k.TenderValue,
      TenderType: k.TenderType,
      TenderSubCategory: k.TenderSubCategory,
      TenderTypeDesc: k.TenderTypeDesc,
      TenderCategory: k.TenderCategory,
      Timer: k.Timer,
      PaymentStatus: k.PaymentStatus,
      StartDate: dateFormat(
        new Date(k.StartDate),
        "mediumDate"
      ),
      ClosingDate: dateFormat(
        new Date(k.ClosingDate),
        "mediumDate"
      ),
    };
    this.setState({ summary: true });
    this.setState(data);
  };
  ViewFile = (k, e) => {
    let filepath = k.Path + "/" + k.FileName;
    window.open(filepath);
    //this.setState({ openFileViewer: true });
  };

  openModal = () => {
    this.setState({ openTracking: true });
  };
  closeModal = () => {
    this.setState({ openTracking: false });
  };
  columntemplate = (value) => {
    return (<div>
      <b style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => this.handViewApplication(value, e)}> View</b></div>);
  };
  render() {


    let headingstyle = {
      color: "#7094db",
    };

    let ViewFile = this.ViewFile;

    if (this.state.summary) {
      return (
        <div>
          <Modal
            visible={this.state.openTracking}
            width="900"
            height="500"
            effect="fadeInUp"
          >
            <div style={{ overflow: "scroll" }}>
              <a
                style={{ float: "right", margin: "10px", color: "red" }}
                href="javascript:void(0);"
                onClick={() => this.closeModal()}
              >
                Close
              </a>
              <br />
              <h4 style={{ "text-align": "center", color: "#1c84c6" }}>
                APPLICATION {this.state.ApplicationNo}
              </h4>

              <div className="container-fluid">
                <div style={{ "overflow-y": "scroll", height: "400px" }}>
                  <table className="table  table-sm  table-striped">
                    <thead class="thead-light">
                      <th>Date</th>
                      <th>Action</th>
                      <th>User</th>
                      <th>Status</th>
                    </thead>

                    {this.state.ApplicationsProgress.map((r, i) =>
                      r.Status === "Pending" ? (
                        <tr>
                          <td>{dateFormat(r.Date, "default")}</td>

                          <td> {r.ExpectedAction}</td>
                          <td> {r.User}</td>
                          <td>{r.Status}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{dateFormat(r.Date, "default")}</td>

                          <td> {r.Action}</td>
                          <td> {r.User}</td>
                          <td>{r.Status}</td>
                        </tr>
                      )
                    )}
                  </table>
                </div>
              </div>
            </div>
          </Modal>
          <ToastContainer />
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-9">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <h2 className="font-weight-bold">
                    Application NO:{" "}
                    <span className="font-weight-bold text-success">
                      {" "}
                      {this.state.ApplicationNo}
                    </span>
                  </h2>
                </li>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <li>
                  <h2 className="font-weight-bold">
                    STATUS:{" "}
                    {this.state.Status === "DECLINED" ? (
                      <span className="text-danger"> {this.state.Status}</span>
                    ) : (
                      <span className="text-success"> {this.state.Status}</span>
                    )}
                  </h2>
                </li>
              </ol>
            </div>
            <div className="col-lg-3">
              <div className="row wrapper ">
                <button
                  type="button"
                  style={{ marginTop: 20 }}
                  onClick={this.openModal}
                  className="btn btn-success float-right"
                >
                  &nbsp; Track progress
                </button>
                &nbsp;
                <button
                  type="button"
                  style={{ marginTop: 20 }}
                  onClick={this.GoBack}
                  className="btn btn-primary float-left"
                >
                  &nbsp; Back
                </button>
              </div>
            </div>
          </div>
          <p></p>
          <div className="border-bottom white-bg p-4">
            <nav>
              <div class="nav nav-tabs " id="nav-tab" role="tablist">
                <a
                  class="nav-item nav-link active font-weight-bold"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#nav-home"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Application Details{" "}
                </a>
                <a
                  class="nav-item nav-link font-weight-bold"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-PEresponse"
                  role="tab"
                  aria-controls="nav-PEresponse"
                  aria-selected="false"
                >
                  PE Response
                </a>
                <a
                  class="nav-item nav-link font-weight-bold"
                  id="nav-CaseAnalysis-tab"
                  data-toggle="tab"
                  href="#nav-CaseAnalysis"
                  role="tab"
                  aria-controls="nav-CaseAnalysis"
                  aria-selected="false"
                >
                  Case Analysis
                </a>
                <a
                  class="nav-item nav-link font-weight-bold"
                  id="nav-Moredetails-tab"
                  data-toggle="tab"
                  href="#nav-Moredetails"
                  role="tab"
                  aria-controls="Moredetails"
                  aria-selected="false"
                >
                  More Details
                </a>
                {/* <a
                            class="nav-item nav-link font-weight-bold"
                            id="nav-Decision-tab"
                            data-toggle="tab"
                            href="#nav-Decision"
                            role="tab"
                            aria-controls="Decision"
                            aria-selected="false"
                            >
                            Decision
                            </a> */}
              </div>
            </nav>
            <div class="tab-content " id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <br />
                <div className="row">
                  <div className="col-sm"><h3>Reapplication: {this.state.Reaplication}</h3></div>
                  <div className="col-sm"><h3>Old case number: {this.state.OldCaseNumber}</h3></div>
                  </div>
                  <div className="row">
                  <div className="col-sm">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label col-md-3">
                        Case Officer
                      </label>
                      <div class="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={this.state.caseOfficer}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label col-md-3">
                        legal researcher
                      </label>
                      <div class="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={this.state.legalresearcherName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label col-md-3">
                        Stenographer
                      </label>
                      <div class="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={this.state.stenographerName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h3 style={headingstyle}> Applicant details</h3>
                    <div className="col-lg-10 border border-success rounded">
                      <table className="table table-borderless table-sm">
                        <tr>
                          <td className="font-weight-bold"> NAME:</td>
                          <td> {this.state.Applicantname}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> EMAIL:</td>
                          <td> {this.state.ApplicantEmail}</td>
                        </tr>

                        <tr>
                          <td className="font-weight-bold"> Mobile:</td>

                          <td> {this.state.ApplicantMobile}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> POBOX:</td>
                          <td>
                            {" "}
                            {this.state.ApplicantPOBox}-
                            {this.state.ApplicantPostalCode}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> Town:</td>
                          <td> {this.state.ApplicantTown}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> Website:</td>
                          <td> {this.state.ApplicantWebsite}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h3 style={headingstyle}>Procuring Entity Details</h3>
                    <div className="col-lg-10 border border-success rounded">
                      <table className="table table-borderless table-sm ">
                        <tr>
                          <td className="font-weight-bold"> Name:</td>
                          <td> {this.state.PEName}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> Email:</td>
                          <td> {this.state.PEEmail}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> Mobile:</td>
                          <td> {this.state.PEMobile}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> POBOX:</td>
                          <td>
                            {" "}
                            {this.state.PEPOBox}-{this.state.PEPostalCode}
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="font-weight-bold"> Town:</td>
                          <td> {this.state.PETown}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> Website:</td>
                          <td> {this.state.PEWebsite}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Tender Details</h3>
                    <div className="col-lg-11 border border-success rounded">
                      <table className="table table-borderless table-sm">
                        <tr>
                          <td className="font-weight-bold">
                            {" "}
                            Application Date:
                          </td>
                          <td> {this.state.FilingDate}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">
                            {" "}
                            Date of Notification of Award/Occurrence of Breach:
                          </td>
                          <td> {this.state.AwardDate}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">
                            {" "}
                            Application Timing:
                          </td>
                          <td> {this.state.Timer}</td>
                        </tr>{" "}
                        <tr>
                          <td className="font-weight-bold"> TenderNo:</td>
                          <td> {this.state.TenderNo}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> TenderName:</td>
                          <td> {this.state.TenderName}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> Tender Value:</td>
                          <td className="font-weight-bold">
                            {" "}
                            {this.formatNumber(this.state.TenderValue)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold"> TenderType:</td>
                          <td> {this.state.TenderTypeDesc}</td>
                        </tr>
                        {this.state.TenderType === "B" ? (
                          <tr>
                            <td className="font-weight-bold">
                              {" "}
                              TenderCategory:
                            </td>
                            <td> {this.state.TenderCategory}</td>
                          </tr>
                        ) : null}{" "}
                        {this.state.TenderType === "B" ? (
                          <tr>
                            <td className="font-weight-bold">
                              {" "}
                              TenderSubCategory:
                            </td>
                            <td> {this.state.TenderSubCategory}</td>
                          </tr>
                        ) : null}
                      </table>
                      <h3 style={headingstyle}>Tender Addendums</h3>
                      <table className="table table-borderless table-sm ">
                        <thead className="thead-light">
                          <th>No</th>
                          <th>StartDate</th>
                          <th>ClosingDate</th>
                          <th>Description</th>
                        </thead>
                        {this.state.AddedAdendums.map((r, i) => (
                          <tr>
                            <td className="font-weight-bold">{r.AdendumNo}</td>

                            <td className="font-weight-bold">
                              {dateFormat(
                                new Date(r.StartDate),
                                "mediumDate"
                              )}
                            </td>
                            <td className="font-weight-bold">
                              {dateFormat(
                                new Date(r.ClosingDate),
                                "mediumDate"
                              )}
                            </td>
                            <td className="font-weight-bold">
                              {r.Description}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
                <br /> 
                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Documents Attached</h3>
                    <div className="col-lg-11 border border-success rounded">
                      <table className="table  table-sm">
                        <thead className="thead-light">
                          <th>ID</th>
                          <th>Document Description</th>
                          <th>FileName</th>
                          <th>Date Uploaded</th>
                          <th>Actions</th>
                        </thead>
                        {this.state.ApplicationDocuments.map(function (k, i) {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{k.Description}</td>
                              <td>{k.FileName}</td>
                              <td>
                                {dateFormat(
                                  new Date(k.DateUploaded),
                                  "mediumDate"
                                )}
                              </td>
                              <td>
                                <a
                                  onClick={(e) => ViewFile(k, e)}
                                  className="text-success"
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                                  View
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Fees</h3>
                    <div className="col-lg-11 border border-success rounded">
                      <div class="col-sm-8">
                        <table class="table table-sm">
                          <thead className="thead-light">
                            <th scope="col">#</th>
                            <th scope="col">Fees description</th>
                            <th scope="col">Value</th>
                          </thead>
                          <tbody>
                            {this.state.Applicationfees.map((r, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{r.EntryType}</td>
                                <td className="font-weight-bold">
                                  {this.formatNumber(r.AmountDue)}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <th></th>
                              <th>Total Amount</th>
                              <th className="font-weight-bold text-danger">
                                {" "}
                                {this.formatNumber(this.state.TotalAmountdue)}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        {this.state.PaymentStatus === "Not Submited" ? (
                          <h4>
                            Fees Status:{" "}
                            <span className="text-danger">NOT PAID</span>{" "}
                          </h4>
                        ) : null}
                        {this.state.PaymentStatus === "Approved" ? (
                          <h4>
                            Fees Status:{" "}
                            <span className="text-success">PAID</span>{" "}
                          </h4>
                        ) : null}
                        {this.state.PaymentStatus === "Submited" ? (
                          <h4>
                            Fees Status:{" "}
                            <span className="text-warning">
                              Payment Pending Confirmation
                            </span>{" "}
                          </h4>
                        ) : null}
                        <h4>
                           Application invoice <a style={{color:"blue"}} onClick={(e) => this.PrintInvoicePDF()}>View invoice</a> 
                          </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Interested Parties</h3>
                    <div className="col-lg-11 border border-success rounded">
                      <table className="table table-sm">
                        <thead className="thead-light">
                          <th>Org Name</th>
                          <th>ContactName</th>
                          <th>Designation</th>
                          <th>Email</th>
                          <th>TelePhone</th>
                          <th>Mobile</th>
                          <th>PhysicalAddress</th>
                        </thead>
                        {this.state.interestedparties.map((r, i) => (
                          <tr>
                            <td>{r.Name}</td>
                            <td> {r.ContactName} </td>
                            <td> {r.Designation} </td>
                            <td> {r.Email} </td>
                            <td> {r.TelePhone} </td>
                            <td> {r.Mobile} </td>
                            <td> {r.PhysicalAddress} </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Additional Submissions</h3>
                    <div className="col-lg-11 border border-success rounded">
                     
                      <h2>Attachments</h2>
                      <table className="table table-borderless table-sm">
                        <thead className="thead-light">
                          <th>ID</th>
                          <th>Description</th>
                          <th>Date Uploaded</th>
                          <th>Uploaded By</th>
                          <th>Actions</th>
                        </thead>
                        {this.state.AdditionalSubmisionsDocuments.map(
                          (k, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td> {k.Description}</td>
                                <td>{dateFormat(k.Create_at, "default")}</td>
                                <td>
                                  {" "}
                                  {k.SubmitedBy} - {k.Category}
                                </td>

                                <td>
                                  <a
                                    onClick={(e) => ViewFile(k, e)}
                                    className="text-success"
                                  >
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    View
                                  </a>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* Start PE response */}
              <div
                class="tab-pane fade"
                id="nav-PEresponse"
                role="tabpanel"
                aria-labelledby="nav-PEresponse-tab"
              >
                <br />
                <div className="row">
                  <div className="col-sm-12">
                    <h3 style={headingstyle}> Response Details</h3>
                    <div className="col-lg-12 border border-success rounded">
                      {this.state.BackgroundInformation ? (
                        <div>
                          <h3>Background </h3>
                          {ReactHtmlParser(this.state.BackgroundInformation)}
                        </div>
                      ) : null}
                      <h3>Response to Applicant Grounds</h3>
                      {this.state.GroundsDetails.length > 0 ? (
                        this.state.GroundsDetails.map(function (k, i) {
                          return (
                            <div>
                              <h3 style={headingstyle}>
                                GroundNo: {k.GroundNO}
                              </h3>
                              <h3 style={headingstyle}>Response</h3>
                              {ReactHtmlParser(k.Response)}
                            </div>
                          );
                        })
                      ) : (
                        <p>Response not submited</p>
                      )}
                      <h3>Response to Applicant Requests</h3>
                      {this.state.PrayersDetails.length > 0 ? (
                        this.state.PrayersDetails.map(function (k, i) {
                          return (
                            <div>
                              <h3 style={headingstyle}>
                                RequestNo: {k.GroundNO}
                              </h3>
                              <h3 style={headingstyle}>Response</h3>
                              {ReactHtmlParser(k.Response)}
                            </div>
                          );
                        })
                      ) : (
                        <p>Response not submited</p>
                      )}
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div className="col-sm-12">
                    <h3 style={headingstyle}> Documents Attached</h3>
                    <div className="col-lg-12 border border-success rounded">
                      <table className="table table-sm">
                        <thead className="thead-light">
                          <th>#</th>
                          <th>Document Description</th>
                          <th>FileName</th>
                          <th>Actions</th>
                        </thead>

                        {this.state.ResponseDocuments.map((k, i) => {
                          return k.Confidential ? (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{k.Description}</td>
                              <td>{k.FileName}</td>
                              <td>
                                <a
                                  onClick={(e) => ViewFile(k, e)}
                                  className="text-success"
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                                  View
                                </a>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{k.Description}</td>
                              <td>{k.Name}</td>
                              <td>
                                <a
                                  onClick={(e) => ViewFile(k, e)}
                                  className="text-success"
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                                  View
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PE response */}
              {/* Start Case Analysis */}
              <div
                class="tab-pane fade"
                id="nav-CaseAnalysis"
                role="tabpanel"
                aria-labelledby="nav-CaseAnalysis-tab"
              >
                <br />
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={this.openopenUploadModal}
                >
                  {" "}
                  Case Analysis
                </h3>
                {this.state.CaseAnalysisDocuments.length > 0 ? (
                  <table className="table table-borderless table-sm">
                    <thead className="thead-light">
                      <th>ID</th>
                      <th>Description</th>
                      <th>Date Uploaded</th>
                      <th>Submited By</th>
                      <th>Actions</th>
                    </thead>
                    {this.state.CaseAnalysisDocuments.map((k, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td> {k.Description}</td>
                          <td>{dateFormat(k.Create_at, "default")}</td>
                          <td> {k.SubmitedBy}</td>

                          <td>
                            <a
                              onClick={(e) => ViewFile(k, e)}
                              className="text-success"
                            >
                              <i class="fa fa-eye" aria-hidden="true"></i>
                              View
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                ) : null}
                <br />
                &nbsp;&nbsp;
                <h3
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={this.openPartiesSubmissionsModal}
                >
                  {" "}
                  Case Analysis
                </h3>
                {this.state.CaseAnalysis.map((r, i) => (
                  <div>
                    <h3 style={{ headingstyle }} className="font-weight strong">
                      {r.Title}
                    </h3>
                    <tr>
                      <td>{ReactHtmlParser(r.Description)}</td>
                    </tr>
                  </div>
                ))}
              </div>
              {/*End  Case Analysis */}
              {/* Start Moredetails */}
              <div
                class="tab-pane fade"
                id="nav-Moredetails"
                role="tabpanel"
                aria-labelledby="nav-Moredetails-tab"
              >
                <br />
                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Board Members</h3>
                    <div className="col-lg-11 border border-success rounded">

                      {this.state.Panels.length > 0 ? (
                        <table className="table table-borderless table-sm ">
                          <thead className="thead-light">
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Role</th>
                          </thead>
                          {this.state.Panels.map((r, i) =>
                            r.Status == "Approved" ? (
                              <tr>
                                <td>{r.ID}</td>
                                <td>{r.Name}</td>
                                <td>{r.Email}</td>
                                <td>{r.Phone}</td>
                                <td>{r.Role}</td>
                              </tr>
                            ) : null
                          )}
                        </table>
                      ) : (
                        <p>Panel Not set</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Advocates</h3>
                    <div className="col-lg-11 border border-success rounded">
                      <table className="table table-sm">
                        <thead className="thead-light">
                          <th>Name</th>
                          <th>Email</th>
                          <th>PO BOX</th>
                          <th>Town</th>
                          <th>Mobile</th>
                          <th>Representing</th>
                        </thead>
                        {this.state.Advocates.map((r, i) => (
                          <tr>
                            <td>{r.Name}</td>
                            <td> {r.POBox} </td>
                            <td> {r.Email} </td>
                            <td> {r.Mobile} </td>
                            <td> {r.Town} </td>
                            <td> {r.Category} </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 ">
                    <h3 style={headingstyle}>Hearing Dates</h3>
                    <div className="col-lg-11 border border-success rounded">
                      <br />
                      <table className="table table-borderless table-sm ">
                        <thead className="thead-light">
                          <th>Venue</th>
                          <th>Date</th>
                          <th>Slot</th>
                          <th>Scheduled By</th>
                        </thead>
                        {this.state.HearingDates.length > 0 ? (
                          this.state.HearingDates.map((r, i) => (
                            <tr>
                              <td>{r.Branch + "-" + r.Room}</td>
                              <td>
                                {dateFormat(
                                  new Date(r.Date),
                                  "mediumDate"
                                )}
                              </td>
                              <td>{r.Slot}</td>
                              <td>{r.Booked_By}</td>
                            </tr>
                          ))
                        ) : (
                          <p>Hearing dates not set</p>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <br />
              </div>
              {/*End  Case Analysis */}
              {/* Start Decision */}
              <div
                class="tab-pane fade"
                id="nav-Decision"
                role="tabpanel"
                aria-labelledby="nav-Decision-tab"
              ></div>
              {/*End  Decision */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <ToastContainer />
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <h2> MY CASES</h2>
                </li>
              </ol>
            </div>
          </div>
          {this.state.IsLoading ? <div class="loader">Loading...</div> :
            <GridComponent dataSource={this.state.Applications}
              allowResizing={true}
              allowTextWrap={true}
              allowFiltering={true} allowPaging={true} pageSettings={{ pageSize: 10 }} >
              <ColumnsDirective>
                <ColumnDirective field='ApplicationNo' headerText='ApplicationNo' width='100' />
                <ColumnDirective field='OldCaseNumber' headerText='OldCaseNumber' width='100' />
                <ColumnDirective field='ApplicantName' headerText='Applicant' />
                <ColumnDirective field='PEName' headerText='PE' />
                <ColumnDirective field='Status' headerText='Status' width='100' />
                <ColumnDirective field='FilingDate' headerText='FilingDate' width='100' type='date' format='dd/MM/yyyy hh:mm:ss' />
                <ColumnDirective field='CaseClosingdate' headerText='Case Closing Date' width='100' type='date' format='dd/MM/yyyy hh:mm:ss' />
                <ColumnDirective field='caseduration' headerText='Duration' width='70' />
                <ColumnDirective template={this.columntemplate} headerText='View' width='70' />

              </ColumnsDirective>
              <Inject services={[Page, Sort, Resize, Filter]} />
            </GridComponent >}
        </div>
      );
    }
  }
}

export default MyCases;
