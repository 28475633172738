import React, { Component } from "react"; 
import './invoice.css';
var dateFormat = require("dateformat");
class Invoice extends Component {
  constructor() {
    super();
    this.state = {
        TotalAmountdue: "",
        Applicationno:"",
        ApplicantTown:"",
        ApplicantPOBox:"",
        ApplicantPostalCode:"",
        ApplicantWebsite:"",
        Invoicedate:"",
        ApplicantEmail:"",
        Applicantname:"",
        ApplicantMobile:"",
        Applicationfees: [],
 
    };
 
  }
  formatNumber = (num) => {
    let newtot = Number(num).toFixed(2);
    return newtot.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  fetchApplicationfees = () => {
      let Applicationid  = this.props.match.params.Applicationid;
      let Applicationno  = this.props.match.params.Applicationno;
    this.setState({ Applicationfees: [] });
    this.setState({ Applicationno: Applicationno });
    this.setState({ TotalAmountdue: "" });
    fetch("/api/applicationfees/" + Applicationid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((Applicationfees) => {
        if (Applicationfees.length > 0) {
          this.setState({ Applicationfees: Applicationfees });
          this.setState({ TotalAmountdue: Applicationfees[0].total });
          this.setState({ Invoicedate: dateFormat(
            new Date(Applicationfees[0].BillDate),
            "fullDate"
          ) });
          
        }
      })
      .catch((err) => {
        // swal("", err.message, "error");
      });
  };
  fetchApplicantDetails = (Applicant) => {
    Applicant=this.props.match.params.Applicantusername;
    fetch("/api/applicants/" + Applicant, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((ApplicantDetails) => {
        if (ApplicantDetails.length > 0) {
          this.setState({
            ApplicantPostalCode: ApplicantDetails[0].PostalCode,
          });
          this.setState({
            ApplicantPOBox: ApplicantDetails[0].POBox,
            ApplicantTown: ApplicantDetails[0].Town,
            ApplicantDetails: ApplicantDetails,
            Applicantname: ApplicantDetails[0].Name,
            ApplicantLocation: ApplicantDetails[0].Location,
            ApplicantMobile: ApplicantDetails[0].Mobile,
            ApplicantEmail: ApplicantDetails[0].Email,
            ApplicantPIN: ApplicantDetails[0].PIN,
            ApplicantWebsite: ApplicantDetails[0].Website,
          });

          this.setState({ ApplicantID: ApplicantDetails[0].ID });
        } 
      })
      .catch((err) => {
      });
  };
  componentDidMount() {
    this.fetchApplicantDetails();
              this.fetchApplicationfees();
 
  }
  PrintInvoicePDF = () => { 
    window.print();
  }
  render() { 
      return (

        <div class="Container">
            <br/>
        <span class="img-container">
          {/* <img class="logo" src="{{ LogoPath }}" alt="" /> */}
        </span>
        <h6 class="headings">REPUBLIC OF KENYA</h6>
        <h6 class="headings">PUBLIC PROCUREMENT ADMINISTRATIVEREVIEW BOARD</h6>
        <h6 class="headings">INVOICE FOR APPLICATION NO { this.state.Applicationno }</h6> 
        <h6 class="headings">INVOICE DATE { this.state.Invoicedate }</h6>         
        <h3 > Applicant details</h3>
                  <div className="col-lg-12 border border-success rounded">
                    <table className="table table-borderless table-sm">
                      <tr>
                        <td className="font-weight-bold"> NAME:</td>
                        <td> {this.state.Applicantname}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> EMAIL:</td>
                        <td> {this.state.ApplicantEmail}</td>
                      </tr>

                      <tr>
                        <td className="font-weight-bold"> Mobile:</td>

                        <td> {this.state.ApplicantMobile}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> POBOX:</td>
                        <td>
                          {" "}
                          {this.state.ApplicantPOBox}-
                          {this.state.ApplicantPostalCode}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Town:</td>
                        <td> {this.state.ApplicantTown}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold"> Website:</td>
                        <td> {this.state.ApplicantWebsite}</td>
                      </tr>
                    </table>
                  </div>     
                  <h3 > Fees details</h3>
        <table class="customers" cellpadding="0" cellspacing="0"> 
          <tr>
          <th scope="col">#</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
          {this.state.Applicationfees.map((r, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{r.EntryType}</td>
                                <td className="font-weight-bold">
                                  {this.formatNumber(r.AmountDue)}
                                </td>
                              </tr>
                            ))}
          <tfoot>
          <tr>
                              <th></th>
                              <th>Total Amount</th>
                              <th className="font-weight-bold">
                                {" "}
                                {this.formatNumber(this.state.TotalAmountdue)}
                              </th>
                            </tr>
          </tfoot>
        </table> 
        <br/>
        <button className="btn-primary float-right"  onClick={(e) => this.PrintInvoicePDF()}>print</button>
  
      </div>
      );
   
  }
}

export default Invoice;
