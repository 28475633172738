import React, { Component } from "react";


class Support extends Component {
    constructor() {
        super();


    }


    render() {

        let divstyle = {
            margin: "50",
            background: "white",
            "padding-left": 40,
            "padding-right": 40,
            "padding-top": 20
        }
        return (


            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10"> 
                <br />
                    <h3>PPRA Contacts</h3>
                    <p><b>Location:</b> 6th Floor KISM Towers, Ngong Road</p>
                    <p> <b>Email:</b> info@ppra.go.ke </p>
                    <p> <b>Address:</b> info@ppra.go.ke </p>
                    <p> <b>P.O Box</b>58535-00200, Nairobi, Kenya </p>
                    <p> <b>Telephone:</b>  +254-020-3244000/2213106 </p>
                    <p> <b>Fax:</b> 2213105/3244377/3244277 </p>
                </div>
            </div>



        );
    }
}

export default Support;
