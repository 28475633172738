import React, { Component } from "react";
class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer">
          <div className="float-right" />
          <div>
            {/* <strong>Copyright</strong> Wilcom Systems Limited &copy; 2019-2099 */}
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
